import { currentPage } from "./module";

export async function renderGalleryImages(images){
    console.log("images:", images);
    // Call the function with your images array
    const galleryContainer = await createGallery(images);

    if(galleryContainer){
        // magnific popup
	    $('.image-popup').magnificPopup({
        	type: 'image',
        	closeOnContentClick: true,
        	closeBtnInside: false,
        	fixedContentPos: true,
        	mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
        	gallery: {
        	  	enabled: true,
        	  	navigateByImgClick: true,
        	  	preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        	},
        	image: {
        	  	verticalFit: true
        	},
        	zoom: {
        	  	enabled: true,
        	  	duration: 300 // don't foget to change the duration also in CSS
        	}
  	    });
    }
}

async function createGallery(images) {
    const galleryContainer = document.getElementById('galleryContainer');
    
    let galleryBox; // Variable to hold current gallery box
    let galleryBoxCounter = 0; // Initialize gallery box counter

    // Loop through each image object and create corresponding HTML
    for (let index = 0; index < images.length; index++) {
        const image = images[index];

        // Create a new gallery box if index is multiple of 4 (0, 4, 8, ...)
        if (index % 4 === 0) {
            galleryBox = document.createElement('div');
            galleryBox.classList.add('gallery-box', 'd-md-flex');
            galleryBoxCounter++; // Increment gallery box counter
        }

        // Create gallery item link
        const galleryItem = document.createElement('a');
        galleryItem.href = image.imgURL;
        galleryItem.classList.add('gallery-item', 'image-popup', 'd-flex', 'justify-content-center', 'align-items-center', 'img');
        galleryItem.setAttribute("data-id", image.id);
            
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    galleryItem.style.backgroundImage = `url(${image.imgURL})`;
                    observer.unobserve(entry.target);
                }
            });
        });
        
        observer.observe(galleryItem);

        
        // Create icon container
        const icon = document.createElement('div');
        icon.classList.add('icon', 'd-flex', 'justify-content-center', 'align-items-center');
        const fullscreenIcon = document.createElement('i');
        fullscreenIcon.classList.add('bx', 'bx-fullscreen');
        icon.appendChild(fullscreenIcon);
        
        // Append icon to gallery item
        galleryItem.appendChild(icon);
        
        // Append gallery item to current gallery box
        galleryBox.appendChild(galleryItem);

        // Append gallery box to gallery container if index is multiple of 4 or last image
        if ((index % 4 === 3) || (index === images.length - 1)) {
            if(galleryContainer){
                galleryContainer.appendChild(galleryBox);
            }
        }

        if(currentPage !== "gallery.html"){
            // Break loop if we have reached 3 gallery boxes
            if (galleryBoxCounter === 3) {
                break;
            }
        }
    }

    return true;
}

