
export const currentPage = getCurrentPageName();

function getCurrentPageName(){
	// Get the full path of the current page
	let path = window.location.pathname;
	// Extract the file name
	let fileName = path.substring(path.lastIndexOf('/') + 1);
	console.log("Currently you're in:", fileName);
	return fileName;
}

export function sendFormObject(form, formData, tableName){
    const formObject = {
        tableName: tableName,
        form: {}
    };

    // Validate form data
    if (!validateFormData(formData)) {
        //alert('Please fill in all fields and provide a valid email address.');
        return;
    }
    document.querySelector(".sending-animation").classList.add("show");

    let country;
    formData.forEach((value, key) => {
        if(key === "country"){
            country = value;
        } else if (key === "phone"){
            formObject.form[key] = country + value;
        } else {
            formObject.form[key] = value;
        }
    });

    console.log("formObject:", formObject);
    var loadingId = "loading-toast";
    var loading = document.getElementById(loadingId);
    if(!loading){
        var loading = document.createElement("p");
        loading.id = loadingId;
    }
  
    if(formObject){
        fetch('https://host.hopehomecalcutta.org/php/form_submission.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formObject)
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            document.querySelector(".sending-animation").classList.remove("show");
            if (data.statusCode === 200) {
                loading.className = "loading-form-p";
                loading.textContent = "Form submitted successfully!";

                alert(data.message);
                // Optionally reset the form after successful submission
                form.reset();
                return true;
            } else {
                loading.className = "loading-form-p-failed";
                loading.textContent = "Form submitting failed!";
                //alert('An error occurred while submitting the form. Please try again.');
                alert(data.message);
                return false;
            }
        })
        .catch((error) => {
            loading.className = "loading-form-p-failed";
            loading.textContent = "Form submitting failed!";
            alert('An error occurred while submitting the form. Please try again.');
            console.error('Error:', error);
            return false;
        });
    } else {
        return false;
    }

    form.appendChild(loading);

    setTimeout(() => {
        loading.remove(); // Remove the loading element after 5 seconds
    }, 7000);
}

// Helper function to capitalize the first letter of a string
function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

// Main function to validate form data
export function validateFormData(formData) {
    let isValid = true;

    // Convert formData to an iterable array of [key, value] pairs
    for (const [key, value] of formData.entries()) {
        const trimmedValue = value.trim(); // Trim whitespace

        if (!trimmedValue) {
            // If the value is empty after trimming
            isValid = false;
            alert(`${capitalizeFirstLetter(key)} is required.`);
            return isValid; // Exit early if an empty field is found
        }

        // Specific validations
        if (key === 'email' && !validateEmail(trimmedValue)) {
            isValid = false;
            alert(`The email address is not valid.`);
            return isValid; // Exit early if an invalid email is found
        }

        if (key === 'phone' && trimmedValue.length !== 10) {
            isValid = false;
            alert(`The phone number must be 10 digits long.`);
            return isValid; // Exit early if an invalid phone number is found
        }
    }

    return isValid;
}
  
export function validateEmail(email) {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
}

export function isExpired(dateStr) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set time to the start of the day for accurate comparison

    // Check if the input contains a date range
    if (dateStr.includes('-')) {
        const [startDateStr, endDateStr] = dateStr.split('-');
        const startDate = parseDate(startDateStr);
        const endDate = parseDate(endDateStr);

        console.log(`Current Date: ${currentDate}`);
        console.log(`Start Date: ${startDate}`);
        console.log(`End Date: ${endDate}`);

        // Check if the current date is within the range
        return !(endDate < currentDate);
    } else {
        const date = parseDate(dateStr);

        console.log(`Current Date: ${currentDate}`);
        console.log(`Given Date: ${date}`);

        // Check if the single date is in the future
        return date > currentDate;
    }
}

function parseDate(dateStr) {
    const [day, month, year] = dateStr.split('/').map(Number);
    return new Date(Date.UTC(year, month - 1, day));
}


export function elementVisibility(id, state){
	const element = document.querySelector(id);
	if(state === 1){
		element.style.display = "block";
	} else if(state === 2){
		element.style.display = "flex";
	} else {
		element.style.display = "none";
	}
}
export function convertDateString(dateStr) {
    // Split the input date string by the hyphen
    let parts = dateStr.split("-");
    
    // Rearrange the parts to "DD-MM-YYYY"
    let formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
    
    return formattedDate;
}

export function formatDateAndTimeString(dateStr) {
    const date = new Date(dateStr);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${day}/${month}/${year} ${hours}:${minutes}`;
}



export function setCookie(name, myArray, minutes) {
    const arrayString = JSON.stringify(myArray);
    console.log("myArray", myArray);
    console.log("arrayString", arrayString);
    
    const d = new Date();
    d.setTime(d.getTime() + (minutes * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    
    // URL-encode the arrayString to handle special characters
    document.cookie = name + "=" + encodeURIComponent(arrayString) + "; path=/; max-age=" + expires;
}


export function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) {
            // Decode the cookie value
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
    }
    return null;
}


export function setSessionStorage(key, array) {
    const timestampedArray = {
        data: array,
        timestamp: new Date().getTime()
    };
    sessionStorage.setItem(key, JSON.stringify(timestampedArray));
}

export function getSessionStorage(key, expirationMinutes) {
    const itemStr = sessionStorage.getItem(key);

    // If the item does not exist, return null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const currentTime = new Date().getTime();
    const expirationTime = expirationMinutes * 60000; // Convert minutes to milliseconds

    // Check if the item is older than the specified expiration time
    if (currentTime - item.timestamp > expirationTime) {
        sessionStorage.removeItem(key); // Remove the item from storage
        return null; // The item has expired
    }

    return item.data; // Return the object array
}

export function renderCountryCode(){
	const select = document.getElementById('country');
	if(select){
		fetch('https://host.hopehomecalcutta.org/php/get_country_codes.php')
        .then(response => response.json())
        .then(data => {
            data.forEach(country => {
                const option = document.createElement('option');
                option.value = country.code;
                option.text = `${country.code} (${country.short})`;
                select.appendChild(option);
            });
        })
        .catch(error => console.error('Error fetching the JSON file:', error));
	}
}